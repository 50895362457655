// PluginHelpers
//
// PluginHelpers for connected EditorJs tool
//
class PluginHelpers {
  constructor (toolName) {
    this.toolName = toolName
  }

  // Creates a temporary element that should be replaced by the correct markup
  // from backend
  //
  buildTemporaryElement (label, data) {
    let inputElement = document.createElement('div')
    const text = document.createTextNode(`Venter på: ${label || this.toolName}`)

    inputElement.appendChild(text)

    inputElement = this.addDataToContainer(
      data,
      inputElement
    )

    return inputElement
  }

  addDataToContainer (data, element) {
    element.dataset.controller = 'components'
    element.dataset.type = this.toolName

    Object.keys(data).forEach((key) => {
      element.dataset[key] = data[key]
    })

    // Add the raw data in order to preserve arrays
    element.dataset.data = JSON.stringify(data)

    return element
  }
}

export default PluginHelpers

/* global AndroidApp */
/* global webkit */

window.BibNative = {
  broadcastIsLoggedIn: function () {
    this.postMessage('isLoggedIn', this.currentUserId())
  },
  broadcastConsentGiven: function () {
    this.postMessage('consentGiven', this.hasGivenTrackingConsent())
  },
  broadcastFormSubmitted: function (options) {
    this.postMessage('formSubmitted', options || '')
  },
  submitForm: function (options) {
    const form = document.querySelector('form')

    const submitButton = form.querySelector('input[type="submit"],button[type="submit"]')

    submitButton.click()
  },
  hasGivenTrackingConsent: function () {
    return this.metaValue('tracking_consent_given') === 'true'
  },
  currentUserId: function () {
    return this.metaValue('current_user_id') || ''
  },
  currentRegion: function () {
    return this.metaValue('current_region') || ''
  },
  isLoggedIn: function () {
    return !!this.currentUserId()
  },
  requestedByNativeApp: function () {
    return this.metaValue('requested_by_native_app') === 'true'
  },
  metaValue (meta) {
    const metaTag = document.querySelector('meta[name="' + meta + '"]')

    if (!metaTag) {
      return undefined
    }
    return metaTag.getAttribute('content')
  },
  postMessage: function (name, value) {
    if (window.webkit && webkit.messageHandlers && webkit.messageHandlers[name]) {
      return webkit.messageHandlers[name].postMessage(value)
    } else if (window.AndroidApp && AndroidApp[name]) {
      console.log('postMessage:', name, value)
      try {
        // Android app will look up data in DOM using BibNative helpers
        return AndroidApp[name]()
      } catch (e) {
        console.log(e)
      }
    }
  }
}

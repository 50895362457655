import { Controller } from '@hotwired/stimulus'

const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const XHRUpload = require('@uppy/xhr-upload')
const Danish = require('@uppy/locales/lib/da_DK')

// And their styles (for UI plugins)
require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')

// ImageUploadController
//
// Converts an input field into a dynamic uploading experience.
//
// Targets
//
// - assetIdField: An `input` field whose values is set to the id of the Photo
//   containing the uploaded image.
//
// - container: Indicates the DOM element where the uploader interface appears.
//
// - cloudinaryKeyField: An `input` field whose values is set to the
//   cloudinary_key value from the Photo containing the uploaded image.
//
// Values
//
// - endpoint: A HTTP request will be POSTed to this URL to upload the
//   image.
export default class extends Controller {
  static targets = [
    'assetIdField',
    'assetTypeField',
    'container',
    'cloudinaryKeyField'
  ]

  static values = {
    endpoint: String
  }

  updateInputFieldWithPhoto (asset) {
    if (this.hasCloudinaryKeyFieldTarget) {
      this.cloudinaryKeyFieldTarget.value = asset.cloudinary_key
    }

    if (this.hasAssetIdFieldTarget) {
      this.assetIdFieldTarget.value = asset.id
    }
    if (this.hasAssetTypeFieldTarget) {
      this.assetTypeFieldTarget.value = asset.type
    }
  }

  connect () {
    const uppy = new Uppy(this.optionsForUppy())
      .use(Dashboard, this.optionsForDashboard())
      .use(XHRUpload, this.optionsForXHRUpload())

    uppy.on('complete', (result) => {
      const uploaded = result.successful || []
      uploaded.forEach(uploadedPhoto => {
        uploadedPhoto.response.body.forEach(photo => {
          this.updateInputFieldWithPhoto(photo)
        })
      })
    })
  }

  optionsForDashboard () {
    return {
      height: 140,
      hideProgressAfterFinish: true,
      inline: true,
      target: this.containerTarget,
      width: '100%'
    }
  }

  optionsForUppy () {
    return {
      allowMultipleUploads: false,
      autoProceed: true,
      locale: Danish
    }
  }

  optionsForXHRUpload () {
    return {
      bundle: true,
      endpoint: this.endpointValue
    }
  }
}

import { Controller } from '@hotwired/stimulus'

// CouponCodeController
//
// Activates the coupon code form
export default class extends Controller {
  static targets = ['couponCode']

  showCouponInput (event) {
    event.preventDefault()

    this.couponCodeTarget.classList.remove('hidden')
  }
}

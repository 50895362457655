import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

export default class RelatedArticles {
  static toolName

  static get toolbox () {
    return {
      title: 'Læs også',
      icon: heroicons('document-text')
    }
  }

  static prepare (config) {
    RelatedArticles.toolName = config.toolName
  }

  constructor ({ data }) {
    this.data = data
    this.helpers = new Helpers(this.constructor.toolName)
  }

  render () {
    const temporaryElement = this.helpers.buildTemporaryElement(
      'Læs også',
      this.data
    )

    return temporaryElement
  }

  save (blockContent) {
    const articleIds = []

    const inputs = blockContent.querySelectorAll('[data-autocomplete-target="hidden"]')
    inputs.forEach(input => {
      const value = input.value
      articleIds.push(value)
    })

    return {
      article_id1: articleIds[0],
      article_id2: articleIds[1]
    }
  }
}

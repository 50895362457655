import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.debugLog('injectNewAds')
    window.preConcept.queue.push({ cmd: 'injectNewAds', args: '' })
  }

  debugLog = function (msg) {
    if (typeof console === 'undefined') return
    if (console.debug) { console.debug(msg) };
  }
}

import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

class SignupGiftBox {
  static toolName

  static get toolbox () {
    return {
      title: 'Velkomsttilbud kasse',
      icon: heroicons('gift')
    }
  }

  static prepare (config) {
    SignupGiftBox.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.helpers = new Helpers(this.constructor.toolName)
  }

  render () {
    let container = document.createElement('div')
    const text = document.createTextNode('Velkomsttilbud kasse')

    container.appendChild(text)

    container = this.helpers.addDataToContainer(
      this.data,
      container
    )

    return container
  }

  save (blockContent) {
    const ids = []
    const count = [0, 1, 2]

    count.forEach(function (count) {
      const field = blockContent.querySelector(`[name="signupgift_ids_${count}"]`)
      if (field && field.value) {
        ids[count] = blockContent.querySelector(`[name="signupgift_ids_${count}"]`).value
      }
    })

    return {
      signupgift_ids: ids
    }
  }
}

export default SignupGiftBox

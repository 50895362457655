import { Controller } from '@hotwired/stimulus'

// ClickPreventerController
//
// Simply prevents default action on clicks
// Useful for, as an example, links that should not be followed when javascript is loaded
export default class extends Controller {
  connect () {
    this.element.addEventListener('click', (event) => {
      event.preventDefault()
    })
  }
}

import { heroicons } from '../icons'
import BackgroundColorTune from './background_color_tune'
import Helpers from './plugin_helpers'
import SizeTune from './size_tune'

class EntryGallery {
  static toolName

  static get toolbox () {
    return {
      title: 'Indholds galleri',
      icon: heroicons('view-boards')
    }
  }

  static prepare (config) {
    EntryGallery.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.data.backgroundColor = this.data.backgroundColor || 'white'
    this.data.size = this.data.size || '1/3'
    this.helpers = new Helpers(this.constructor.toolName)

    this.backgroundColorTune = new BackgroundColorTune(api, this.data.backgroundColor)
    this.sizeTune = new SizeTune(api, this.data.size)
  }

  render () {
    const temporaryElement = this.helpers.buildTemporaryElement(
      'Galleri med frit indhold',
      this.data
    )

    return temporaryElement
  }

  renderSettings () {
    return [
      this.sizeTune.sizeTunesWrapper(
        // callback function
        (tune) => {
          this.data.size = tune.size
        }
      ),
      this.backgroundColorTune.colorTuneWrapper(
        // callback function
        (tune) => {
          this.data.backgroundColor = tune.color
        }
      )
    ]
  }

  cleanupEntryIds (entryIds) {
    // Remove empty entries that have null, undefined or 0 values
    return entryIds.filter(function (id) {
      return id
    })
  }

  save (blockContent) {
    const count = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    const ids = []
    let title = ''

    count.forEach(function (count) {
      const entryField = blockContent.querySelector(`[name="entry_ids_${count}"]`)

      if (entryField && entryField.value) {
        const entryId = entryField.value
        const titleField = blockContent.querySelector(`[name="entries_${count}_custom_title"]`)
        let titleForEntry = ''

        if (titleField && titleField.value) {
          titleForEntry = titleField.value
        }

        ids[count] = { id: entryId, title: titleForEntry }
      }
    })

    if (blockContent.querySelector('[name="gallery_title"]')) {
      title = blockContent.querySelector('[name="gallery_title"]').value
    }

    return {
      entry_ids: this.cleanupEntryIds(ids),
      gallery_title: title,
      size: this.data.size,
      backgroundColor: this.data.backgroundColor
    }
  }
}

export default EntryGallery

import { Controller } from '@hotwired/stimulus'

// AutoSubmitController
//
// Simply autosubmits the form
export default class extends Controller {
  connect () {
    this.element.submit()
  }
}

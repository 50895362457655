import { Controller } from '@hotwired/stimulus'

import anime from 'animejs/lib/anime.es.js'

export default class extends Controller {
  static targets = ['content']

  appear () {
    this.element.classList.remove('hidden')
    anime({
      targets: this.element,
      opacity: [
        { value: 0, duration: 0 },
        { value: 1, duration: 2000 }
      ]
    })

    anime({
      targets: this.contentTarget,
      translateY: [
        { value: 200, duration: 0 },
        { value: 0, duration: 2000 }
      ]
    })

    document.addEventListener('click', this.handleOutsideClicks)
  }

  connect () {
    setTimeout(() => this.appear(), 5000)
  }

  disappear () {
    this.element.classList.add('hidden')
    this.trackAnnoyingClosing()
  }

  dismiss () {
    this.disappear()
  }

  handleOutsideClicks = (event) => {
    if (!this.contentTarget.contains(event.target)) {
      this.dismiss()
    }
  }

  trackAnnoyingClosing () {
    window.Rails.ajax({
      type: 'delete',
      url: '/newsletter_popup'
    })
  }
}

import { Controller } from '@hotwired/stimulus'

// CategoriesInputController
//
// Adds interactivity to Forms::Inputs::CategoriesComponent
export default class extends Controller {
  static targets = ['inputFields', 'template']

  addCategory (event) {
    event.preventDefault()
    this.addInputFieldsForCategory()
  }

  addInputFieldsForCategory (category) {
    const inputs = this.templateTarget.content.cloneNode(true)

    this.inputFieldsTarget.appendChild(inputs)
  }
}

import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

class PaidContent {
  static toolName

  static get toolbox () {
    return {
      title: 'Betalt indhold',
      icon: heroicons('currency-dollar')
    }
  }

  static prepare (config) {
    PaidContent.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.helpers = new Helpers(this.constructor.toolName)
  }

  render () {
    let container = document.createElement('div')
    const text = document.createTextNode('Betalt indhold')

    container.appendChild(text)

    container = this.helpers.addDataToContainer(
      this.data,
      container
    )

    return container
  }

  save (blockContent) {
    let photoId = ''
    let title = ''
    let url = ''

    if (blockContent.querySelector('[name="photo_id"]')) {
      const value = blockContent.querySelector('[name="photo_id"]').value

      photoId = value
    }

    if (blockContent.querySelector('[name="title"]')) {
      const value = blockContent.querySelector('[name="title"]').value

      title = value
    }

    if (blockContent.querySelector('[name="url"]')) {
      const value = blockContent.querySelector('[name="url"]').value

      url = value
    }

    return {
      photo_id: photoId,
      title,
      url
    }
  }
}

export default PaidContent

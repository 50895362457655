import { Controller } from '@hotwired/stimulus'

const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const XHRUpload = require('@uppy/xhr-upload')
const Danish = require('@uppy/locales/lib/da_DK')

// // And their styles (for UI plugins)
require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')

// CloudinaryImageInputController
//
// Adds interactivity to Form::Input::CloudinaryImageComponent, allowing for
// upload of a single image to Cloudinary.
//
// After upload a hidden input field is updated with the key of the uploaded
// photo.
//
// Targets
//
// - `container`: This is where the upload/drag'n'drop element goes.
// - `image`: Image element showing the currently uploaded image.
// - `input`: The input field that receives the key of the uploaded file.
export default class extends Controller {
  static targets = ['container', 'image', 'input']

  static values = {
    uploadFileEndpoint: String
  }

  connect () {
    const uppy = new Uppy(this.optionsForUppy())
      .use(Dashboard, this.optionsForDashboard())
      .use(XHRUpload, this.optionsForXHRUpload())

    uppy.on('complete', (result) => {
      const uploaded = result.successful || []
      const uploadedImage = uploaded[0]
      if (uploadedImage) {
        this.inputTarget.value = uploadedImage.response.body[0].cloudinary_key
      }
    })
  }

  delete () {
    window.fetch(
      this.uploadFileEndpointValue + '/' + this.inputTarget.value,
      {
        method: 'DELETE'
      }
    ).then(response => {
      this.imageTarget.remove()
      this.inputTarget.value = ''
    })
  }

  optionsForDashboard () {
    return {
      height: 340,
      inline: true,
      target: this.containerTarget,
      width: '100%'
    }
  }

  optionsForUppy () {
    return {
      autoProceed: true,
      locale: Danish,
      restrictions: {
        maxNumberOfFiles: 1
      }
    }
  }

  optionsForXHRUpload () {
    return {
      bundle: true,
      endpoint: this.uploadFileEndpointValue
    }
  }
}

import { Controller } from '@hotwired/stimulus'

// BulkController
//
// Clientside support for bulk actions in tables.
//
// Targets
//
// - `checkbox`: A checkbox that selects each row for the bulk action.
// - `submit`: The submit button that triggers the bulk action. The button is
//             enabled when at least one row is selected.
export default class extends Controller {
  static targets = ['checkbox', 'submit']

  connect () {
    if (!this.hasCheckboxTarget) return
    if (!this.hasSubmitTarget) return

    this.interval = setInterval(() => {
      this.setSubmitState()
    }, 100)
  }

  disconnect () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  setSubmitState () {
    const allUnchecked = this.checkboxTargets.every(checkbox => !checkbox.checked)
    this.submitTarget.disabled = allUnchecked
  }

  // Sets all checkboxes to the same state as the clicked checkbox
  toggleAll (event) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = event.target.checked
    })
  }
}

import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ['slide']

  static values = {
    // Number of miliseconds to wait before cycling to the next slide
    interval: { type: Number, default: 10000 }
  }

  connect () {
    if (!this.hasSlideTarget) return
    if (this.slideTargets.length === 1) return

    this.interval = setInterval(() => {
      this.cycleImage()
    }, this.intervalValue)

    this.currentSlideIndex = 0
  }

  currentSlide () {
    return this.slideTargets[this.currentSlideIndex]
  }

  cycleImage () {
    enter(this.nextSlide())
    leave(this.currentSlide())
    this.currentSlideIndex = this.nextSlideIndex()
  }

  disconnect () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  nextSlide () {
    return this.slideTargets[this.nextSlideIndex()]
  }

  nextSlideIndex () {
    return (this.currentSlideIndex + 1) % this.slideTargets.length
  }
}

import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

class ClubWelcomeCard {
  static toolName

  static get toolbox () {
    return {
      title: 'Velkomst kort til klubben',
      icon: heroicons('user-circle')
    }
  }

  static prepare (config) {
    ClubWelcomeCard.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.helpers = new Helpers(this.constructor.toolName)
  }

  render () {
    let container = document.createElement('div')
    const text = document.createTextNode('Klub velkomst kort')

    container.appendChild(text)

    container = this.helpers.addDataToContainer(
      this.data,
      container
    )

    return container
  }

  save (blockContent) {
    let text = ''
    if (blockContent.querySelector('[name="text"]')) {
      text = blockContent.querySelector('[name="text"]').value
    }

    return {
      text
    }
  }
}

export default ClubWelcomeCard

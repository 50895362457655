import { Controller } from '@hotwired/stimulus'

// RecommendedAgeInputController
//
// Adds interactivity to Forms::Inputs::RecommendedAgeComponent
export default class extends Controller {
  static targets = ['selector']

  connect () {
    this.updateSelectFields()

    this.selectorTargets.forEach((element) => {
      element.addEventListener(
        'change',
        this.selectorTargetChanged.bind(this)
      )
    })
  }

  disconnect () {
    this.selectorTargets.forEach((element) => {
      element.removeEventListener(
        'change',
        this.selectorTargetChanged.bind(this)
      )
    })
  }

  selectorTargetChanged (event) {
    this.updateSelectFields()
  }

  updateSelectFields () {
    this.selectorTargets.forEach((element) => {
      const value = element.value
      const selector = `[data-enable-for='${value}']`
      const fieldsForValue = this.element.querySelectorAll(selector)

      fieldsForValue.forEach(inputField => {
        inputField.disabled = !element.checked
      })
    })
  }
}

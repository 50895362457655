import { Controller } from '@hotwired/stimulus'
import EditorJS from '@editorjs/editorjs'

import About from '../editorjs/plugins/about'
import Ad from '../editorjs/plugins/ad'
import AppAd from '../editorjs/plugins/app_ad'
import ArticleCard from '../editorjs/plugins/article_card'
import Bubbles from '../editorjs/plugins/bubbles'
import Calendar from '../editorjs/plugins/calendar'
import CategoryList from '../editorjs/plugins/category_list'
import ClubAd from '../editorjs/plugins/club_ad'
import ClubCalendar from '../editorjs/plugins/club_calendar'
import ClubCategoryGroupList from '../editorjs/plugins/club_category_group_list'
import ClubNavigation from '../editorjs/plugins/club_navigation'
import ClubOfferCard from '../editorjs/plugins/club_offer_card'
import ClubWelcomeCard from '../editorjs/plugins/club_welcome_card'
import EntryGallery from '../editorjs/plugins/entry_gallery'
import EntryGrid from '../editorjs/plugins/entry_grid'
import EventCard from '../editorjs/plugins/event_card'
import HeroTopic from '../editorjs/plugins/hero_topic'
import HouseAd from '../editorjs/plugins/house_ad'
import PaidContent from '../editorjs/plugins/paid_content'
import PlaceCard from '../editorjs/plugins/place_card'
import Video from '../editorjs/plugins/video'

// FrontpageEditorController
//
// Adds a block-styled editor to a given input field.
//
// Targets
//
// - editor: The DOM element that will be replaced with the EditorJS instance.
//
// - input: The input element that will be updated with the data structure
//   generated by EditorJS.
//
export default class extends Controller {
  static targets = ['editor', 'input']

  static values = {
    uploadFileEndpoint: String
  }

  autoUpdateContent () {
    this.intervalId = setInterval(this.updateInputWithContents.bind(this), 1000)
  }

  buildEditor () {
    return new EditorJS(this.editorOptions())
  }

  connect () {
    this.editor = this.buildEditor()
    this.autoUpdateContent()
  }

  currentContents () {
    const inputValue = this.inputTarget.value
    if (inputValue) {
      return JSON.parse(inputValue)
    } else {
      return {}
    }
  }

  disconnect () {
    clearInterval(this.intervalId)
  }

  editorOptions () {
    return {
      data: this.currentContents(),
      holder: this.editorTarget,
      onChange: () => {
        this.updateInputWithContents()
      },
      tools: this.tools()
    }
  }

  tools () {
    return {
      about: About,
      ad: Ad,
      app_ad: AppAd,
      article_card: ArticleCard,
      bubbles: Bubbles,
      calendar: Calendar,
      category_list: CategoryList,
      club_calendar: ClubCalendar,
      club_category_group_list: ClubCategoryGroupList,
      club_navigation: ClubNavigation,
      club_offer_card: ClubOfferCard,
      club_welcome_card: ClubWelcomeCard,
      hero_topic: HeroTopic,
      house_ad: HouseAd,
      paid_content: PaidContent,
      place_card: PlaceCard,
      entry_gallery: EntryGallery,
      entry_grid: EntryGrid,
      event_card: EventCard,
      club_ad: ClubAd,
      video: Video
    }
  }

  updateInputWithContents () {
    this.editor.save().then((outputData) => {
      this.inputTarget.value = JSON.stringify(outputData)
    }).catch((error) => {
      console.error('Saving failed: ', error)
    })
  }
}

/* global window */
/* global grecaptcha */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    sitekey: String
  }

  connect () {
    this.addDataElement()
    this.addCallbackFunction()
    this.loadSDK()
    this.setupCaptcha()
  }

  addCallbackFunction () {
    window.userSignedUp = this.userSignedUp.bind(this)
  }

  addDataElement () {
    if (!document.getElementById('google-recaptcha-data')) {
      const element = document.createElement('div')

      element.id = 'google-recaptcha-data'
      element.classList.add('g-recaptcha')
      element.setAttribute('defer', 'true')
      element.setAttribute('data-callback', 'userSignedUp')
      element.setAttribute('data-badge', 'bottomleft')
      element.setAttribute('data-sitekey', this.sitekeyValue)
      element.setAttribute('data-size', 'invisible')

      document.body.append(element)
    }
  }

  executeCaptcha (event) {
    if (window.grecaptcha) {
      event.preventDefault()
      grecaptcha.execute()
    }
  }

  setupCaptcha () {
    this.element.form.addEventListener('submit', this.executeCaptcha.bind(this))
  }

  userSignedUp (token) {
    const tokenField = document.getElementById('signup_verification')
    tokenField.value = token

    const signupForm = document.getElementById('signup_form')
    signupForm.submit()
  }

  loadSDK () {
    if (!document.getElementById('google-recaptcha-sdk')) {
      const script = document.createElement('script')

      script.id = 'google-recaptcha-sdk'
      script.setAttribute('defer', 'true')
      script.src = 'https://www.google.com/recaptcha/api.js'

      document.body.append(script)
    }
  }
}

import { addActiveClassToButton, handleTuneToggleAsRadioButton } from './tune_handlers'
import { heroicons } from '../icons'

// BackgroundColorTune
//
// Background color tune helpers for connected EditorJs tool
//
class BackgroundColorTune {
  constructor (api, color) {
    this.api = api
    this.color = color
  }

  settings () {
    const blushIcon = heroicons('color-blush')
    const blushPaleIcon = heroicons('color-blush-pale')
    const perrywinkleIcon = heroicons('color-perrywinkle')
    const perrywinklePaleIcon = heroicons('color-perrywinkle-pale')
    const pigPinkIcon = heroicons('color-pig-pink')
    const pigPinkPaleIcon = heroicons('color-pig-pink-pale')
    const sandyIcon = heroicons('color-sandy')
    const sandyPaleIcon = heroicons('color-sandy-pale')
    const softGreenIcon = heroicons('color-soft-green')
    const softGreenPaleIcon = heroicons('color-soft-green-pale')
    const whiteIcon = heroicons('color-white')

    return [
      {
        name: 'Baggrundsfarve: hvid',
        icon: whiteIcon,
        color: 'white'
      },
      {
        name: 'Baggrundsfarve: blush-pale',
        icon: blushPaleIcon,
        color: 'blush-pale'
      },
      {
        name: 'Baggrundsfarve: perrywinkle-pale',
        icon: perrywinklePaleIcon,
        color: 'perrywinkle-pale'
      },
      {
        name: 'Baggrundsfarve: pig-pink-pale',
        icon: pigPinkPaleIcon,
        color: 'pig-pink-pale'
      },
      {
        name: 'Baggrundsfarve: sandy-pale',
        icon: sandyPaleIcon,
        color: 'sandy-pale'
      },
      {
        name: 'Baggrundsfarve: soft-green-pale',
        icon: softGreenPaleIcon,
        color: 'soft-green-pale'
      },
      {
        name: 'Baggrundsfarve: blush',
        icon: blushIcon,
        color: 'blush'
      },
      {
        name: 'Baggrundsfarve: perrywinkle-pale',
        icon: perrywinkleIcon,
        color: 'perrywinkle'
      },
      {
        name: 'Baggrundsfarve: pig-pink',
        icon: pigPinkIcon,
        color: 'pig-pinke'
      },
      {
        name: 'Baggrundsfarve: sandy',
        icon: sandyIcon,
        color: 'sandy'
      },
      {
        name: 'Baggrundsfarve: soft-green',
        icon: softGreenIcon,
        color: 'soft-green'
      }
    ]
  }

  addOnHoverToButton (button, tune) {
    this.api.tooltip.onHover(button, tune.name)
  }

  appendButtons (wrapper, callback) {
    this.settings().forEach(tune => {
      const button = document.createElement('div')

      button.classList.add('cdx-settings-button')

      if (this.color === tune.color) {
        addActiveClassToButton(button)
      }

      button.innerHTML = tune.icon
      wrapper.appendChild(button)

      button.addEventListener('click', function () {
        handleTuneToggleAsRadioButton(button)
        callback(tune)
      })

      this.addOnHoverToButton(button, tune)
    })

    return wrapper
  }

  colorTuneWrapper (callback) {
    let wrapper = document.createElement('div')

    wrapper = this.appendButtons(wrapper, callback)

    return wrapper
  }
}

export default BackgroundColorTune

import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'

const Uppy = require('@uppy/core')
const DragDrop = require('@uppy/drag-drop')
const XHRUpload = require('@uppy/xhr-upload')
const Danish = require('@uppy/locales/lib/da_DK')

// // And their styles (for UI plugins)
require('@uppy/core/dist/style.css')
require('@uppy/drag-drop/dist/style.css')

// PhotosInputGroupControler
//
// Adds interactivity to Form::InputGroup::PhotosComponent
export default class extends Controller {
  static targets = ['container', 'inputFields', 'dragDrop']

  static values = {
    dropSize: String,
    formAttribute: String,
    formObject: String,
    photosEditEndpoint: String,
    uploadFileEndpoint: String
  }

  addPhotoInputFields (html) {
    // the response from photosEditEndpoint is html
    // with the new photo inputs - effectively adding the photos to the form
    this.inputFieldsTarget.insertAdjacentHTML('beforeend', html)
  }

  setupUppy () {
    // Clear previous dragdrop html before adding new
    // This enables continuous dragdropping of photos
    this.dragDropTarget.innerHTML = ''

    const uppy = new Uppy(this.optionsForUppy())
      .use(DragDrop, this.optionsForDragDrop())
      .use(XHRUpload, this.optionsForXHRUpload())

    uppy.on('complete', (result) => {
      const photos = []
      const uploaded = result.successful || []
      uploaded.forEach(uploadedPhoto => {
        uploadedPhoto.response.body.forEach(photo => {
          photos.push(photo)
        })
      })

      const photoIds = photos.map(photo => photo.id).toString()

      const dataParams = new URLSearchParams(
        {
          form_attribute: this.formAttributeValue,
          form_object: this.formObjectValue,
          photo_ids: photoIds
        }
      ).toString()

      Rails.ajax({
        type: 'get',
        url: this.photosEditEndpointValue,
        data: dataParams,
        success: (response) => {
          this.addPhotoInputFields(response.body.innerHTML)
          this.setupUppy()
        }
      })
    })
  }

  connect () {
    this.setupUppy()
  }

  optionsForDragDrop () {
    return {
      target: this.dragDropTarget,
      locale: {
        strings: {
          dropHereOr: 'Træk og slip fil her eller vælg fil'
        },
        browse: 'vælg fil'
      },
      height: `${this.dropSizeValue}px`
    }
  }

  optionsForUppy () {
    return {
      autoProceed: true,
      locale: Danish,
      restrictions: {
        maxNumberOfFiles: 1
      }
    }
  }

  optionsForXHRUpload () {
    return {
      bundle: true,
      endpoint: this.uploadFileEndpointValue
    }
  }
}

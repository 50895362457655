/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'
import NestedForm from 'stimulus-rails-nested-form'
import Rails from '@rails/ujs'
import Remote from 'stimulus-remote-rails'
import Reveal from 'stimulus-reveal-controller'
import { Autocomplete } from 'stimulus-autocomplete'

import '../scripts/native_forms'
import '../scripts/native_helpers'

const Turbolinks = require('turbolinks')
Turbolinks.start()

window.Rails = Rails
Rails.start()

const application = Application.start()
const context = require.context('../scripts/controllers', true, /\.js$/)
application.load(definitionsFromContext(context))

application.register('autocomplete', Autocomplete)
application.register('nested-form', NestedForm)
application.register('remote', Remote)
application.register('reveal', Reveal)

import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

class PlanSelector {
  static toolName

  static get toolbox () {
    return {
      title: 'Plan vælger',
      icon: heroicons('credit-card')
    }
  }

  static prepare (config) {
    PlanSelector.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.helpers = new Helpers(this.constructor.toolName)
  }

  render () {
    const temporaryElement = this.helpers.buildTemporaryElement(
      'Plan vælger',
      this.data
    )

    return temporaryElement
  }

  save (blockContent) {
    const planIds = []

    const selectedPlans = blockContent.querySelectorAll('[name="plan_ids"]')
    selectedPlans.forEach(plan => {
      planIds.push(plan.value)
    })

    return { plan_ids: planIds }
  }
}

export default PlanSelector

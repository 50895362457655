import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

export default class NewsletterSignup {
  static toolName

  static get toolbox () {
    return {
      title: 'Nyhedsbrevstilmelding',
      icon: heroicons('newspaper')
    }
  }

  static prepare (config) {
    NewsletterSignup.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.helpers = new Helpers(this.constructor.toolName)
  }

  render () {
    let container = document.createElement('div')
    const text = document.createTextNode('Newsletter')

    container.appendChild(text)

    container = this.helpers.addDataToContainer(
      this.data,
      container
    )

    return container
  }

  save (blockContent) {
    let regionId = ''

    if (blockContent.querySelector('[name="region_id"]')) {
      const value = blockContent.querySelector('[name="region_id"]').value

      regionId = value
    }

    return {
      region_id: regionId
    }
  }
}

import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

import BackgroundColorTune from './background_color_tune'

class HeroTopic {
  static toolName

  static get toolbox () {
    return {
      title: 'Emneboks',
      icon: heroicons('academic-cap')
    }
  }

  static prepare (config) {
    HeroTopic.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.data.backgroundColor = this.data.backgroundColor || 'sandy-pale'
    this.helpers = new Helpers(this.constructor.toolName)

    this.backgroundColorTune = new BackgroundColorTune(api, this.data.backgroundColor)
  }

  render () {
    const temporaryElement = this.helpers.buildTemporaryElement(
      'Emneboks',
      this.data
    )

    return temporaryElement
  }

  renderSettings () {
    return [
      this.backgroundColorTune.colorTuneWrapper(
        (tune) => {
          this.data.backgroundColor = tune.color
        }
      )
    ]
  }

  save (blockContent) {
    const count = [0, 1, 2]
    const ids = []
    count.forEach(function (count) {
      const entryField = blockContent.querySelector(`[name="entry_ids_${count}"]`)
      if (entryField && entryField.value) {
        let titleForEntry = ''

        if (blockContent.querySelector(`[name="entries_${count}_custom_title"]`) && blockContent.querySelector(`[name="entries_${count}_custom_title"]`).value) {
          titleForEntry = blockContent.querySelector(`[name="entries_${count}_custom_title"]`).value
        }

        ids[count] = { id: entryField.value, title: titleForEntry }
      }
    })

    let photoId = ''
    if (blockContent.querySelector('[name="photo_id"]')) {
      photoId = blockContent.querySelector('[name="photo_id"]').value
    }

    let title = ''
    if (blockContent.querySelector('[name="title"]')) {
      title = blockContent.querySelector('[name="title"]').value
    }

    let url = ''
    if (blockContent.querySelector('[name="url"]')) {
      url = blockContent.querySelector('[name="url"]').value
    }

    return {
      backgroundColor: this.data.backgroundColor,
      entry_ids: ids,
      photoId,
      title,
      url
    }
  }
}

export default HeroTopic

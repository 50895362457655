import { addActiveClassToButton, handleTuneToggleAsRadioButton } from './tune_handlers'
import { heroicons } from '../icons'

// RowTune
//
// Row tune helpers for connected EditorJs tool
//
class RowTune {
  constructor (api, rows) {
    this.api = api
    this.rows = rows
  }

  settings () {
    return [
      {
        name: '1 Række',
        icon: heroicons('chevron-down'),
        rows: '1'
      },
      {
        name: '2 Rækker',
        icon: heroicons('chevron-double-down'),
        rows: '2'
      }
    ]
  }

  addOnHoverToButton (button, tune) {
    this.api.tooltip.onHover(button, tune.name)
  }

  appendButtons (wrapper, callback) {
    this.settings().forEach(tune => {
      const button = document.createElement('div')

      button.classList.add('cdx-settings-button')

      if (this.rows === tune.rows) {
        addActiveClassToButton(button)
      }

      button.innerHTML = tune.icon
      wrapper.appendChild(button)

      button.addEventListener('click', function () {
        handleTuneToggleAsRadioButton(button)
        callback(tune)
      })

      this.addOnHoverToButton(button, tune)
    })

    return wrapper
  }

  rowTunesWrapper (callback) {
    let wrapper = document.createElement('div')

    wrapper = this.appendButtons(wrapper, callback)

    return wrapper
  }
}

export default RowTune

import { make } from './ui';
import { heroicons } from '../../icons';

const startIcon = heroicons('position-start');
const endIcon = heroicons('position-end');
const centerIcon = heroicons('position-center');

/**
 * Working with Block Tunes
 */
export default class Tunes {
  // Removes buttonActive classes from all tune buttons
  clearButtonsActiveState() {
    this.buttons.forEach((button) => {
      button.classList.remove(this.CSS.buttonActive)
    })
  }

  /**
   * @param {object} tune - image tool Tunes managers
   * @param {object} tune.api - Editor API
   * @param {object} tune.actions - list of user defined tunes
   * @param {Function} tune.onChange - tune toggling callback
   */
  constructor({ api, actions, onChange }) {
    this.api = api;
    this.actions = actions;
    this.onChange = onChange;
    this.buttons = [];
  }

  /**
   * Available Image tunes
   *
   * @returns {{name: string, icon: string, title: string}[]}
   */
  static get tunes() {
    return [
      {
        name: 'start',
        icon: startIcon,
        title: 'Venstrestil',
      },
      {
        name: 'center',
        icon: centerIcon,
        title: 'Midterstil',
      },
      {
        name: 'end',
        icon: endIcon,
        title: 'Højrestil',
      },
    ];
  }

  /**
   * Styles
   *
   * @returns {{wrapper: string, buttonBase: *, button: string, buttonActive: *}}
   */
  get CSS() {
    return {
      wrapper: '',
      buttonBase: this.api.styles.settingsButton,
      button: 'image-tool__tune',
      buttonActive: this.api.styles.settingsButtonActive,
    };
  }

  /**
   * Makes buttons with tunes to pull left or right or center the image.
   *
   * @param {ImageToolData} toolData - generate Elements of tunes
   * @returns {Element}
   */
  render(toolData) {
    const currentPosition = toolData.position || 'center';
    const wrapper = make('div', this.CSS.wrapper);

    this.buttons = [];

    const tunes = Tunes.tunes.concat(this.actions);

    tunes.forEach(tune => {
      const title = this.api.i18n.t(tune.title);
      let classes = [this.CSS.buttonBase, this.CSS.button]
      if (tune.name == currentPosition) {
        classes.push(this.CSS.buttonActive)
      }
      const el = make(
        'div',
        classes,
        {
          innerHTML: tune.icon,
          title,
        }
      );

      el.addEventListener('click', () => {
        this.tuneClicked(tune.name, tune.action);
      });

      el.dataset.tune = tune.name;

      this.buttons.push(el);

      this.api.tooltip.onHover(el, title, {
        placement: 'top',
      });

      wrapper.appendChild(el);
    });

    return wrapper;
  }

  /**
   * Clicks to one of the tunes
   *
   * @param {string} tuneName - clicked tune name
   * @param {Function} customFunction - function to execute on click
   */
  tuneClicked(tuneName, customFunction) {
    if (typeof customFunction === 'function') {
      if (!customFunction(tuneName)) {
        return false;
      }
    }

    this.setActiveStateForTune(tuneName)
    this.onChange(tuneName);
  }

  setActiveStateForTune(tuneName) {
    this.clearButtonsActiveState();
    const buttonForTune = this.buttons.find(
      el => el.dataset.tune === tuneName
    );

    if (buttonForTune) {
      buttonForTune.classList.add(this.CSS.buttonActive);
    }
  }
}

import { Controller } from '@hotwired/stimulus'

// SubmitOnChangeController
//
// Submit a form whenever form field changes
export default class extends Controller {
  connect () {
    const inputs = this.element.querySelectorAll('input, select')
    inputs.forEach(function (input) {
      input.addEventListener('change', this.handleChange.bind(this, input))
    }.bind(this))
  }

  handleChange (input) {
    input.form.submit()
  }
}

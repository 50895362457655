import { heroicons } from '../icons'
import BackgroundColorTune from './background_color_tune'
import Helpers from './plugin_helpers'
import RowTune from './row_tune'
import SizeTune from './size_tune'

class EventCard {
  static toolName

  static get toolbox () {
    return {
      title: 'Indholds kort, arrangement',
      icon: heroicons('ticket')
    }
  }

  static prepare (config) {
    EventCard.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.data.backgroundColor = this.data.backgroundColor || 'white'
    this.data.rows = this.data.rows || '1'
    this.data.size = this.data.size || '1/3'
    this.helpers = new Helpers(this.constructor.toolName)

    this.backgroundColorTune = new BackgroundColorTune(api, this.data.backgroundColor)
    this.rowTune = new RowTune(api, this.data.rows)
    this.sizeTune = new SizeTune(api, this.data.size)
  }

  render () {
    const temporaryElement = this.helpers.buildTemporaryElement(
      'Indholdselement: Arrangement',
      this.data
    )

    return temporaryElement
  }

  renderSettings () {
    return [
      this.rowTune.rowTunesWrapper(
        // callback function
        (tune) => {
          this.data.rows = tune.rows
        }
      ),
      this.sizeTune.sizeTunesWrapper(
        // callback function
        (tune) => {
          this.data.size = tune.size
        }
      ),
      this.backgroundColorTune.colorTuneWrapper(
        // callback function
        (tune) => {
          this.data.backgroundColor = tune.color
        }
      )
    ]
  }

  save (blockContent) {
    let entryId = ''
    let title = ''

    if (blockContent.querySelector('[name="entry_id"]')) {
      const value = blockContent.querySelector('[name="entry_id"]').value

      entryId = value
    }

    if (blockContent.querySelector('[name="custom_title"]')) {
      title = blockContent.querySelector('[name="custom_title"]').value
    }

    return {
      custom_title: title,
      entry_id: entryId,
      rows: this.data.rows,
      size: this.data.size,
      backgroundColor: this.data.backgroundColor
    }
  }
}

export default EventCard

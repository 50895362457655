/* global Event */

import { Controller } from '@hotwired/stimulus'

const navigationFieldsChangedEvent = new Event('navigationFieldsChanged')

// NavigationEditorController
//
// Adds interactivity to Forms::InputGroups::NavigationEditorComponent
export default class extends Controller {
  static targets = ['json', 'inputFields', 'navigationItem', 'template']
  connect () {
    this.updateInputFields()

    if (this.hasJsonTarget) {
      document.addEventListener('navigationFieldsChanged', this.handleNavigationFieldsChanged.bind(this))
    }
  }

  addNavigationItem (event) {
    event.preventDefault()

    this.addNavigationItemElement(undefined, event.target.parentNode)
  }

  addNavigationItemElement (item, parentItem) {
    const inputs = this.templateTarget.content.cloneNode(true).querySelector('div')

    if (item) {
      inputs.querySelector('[name=label]').value = item.label
      inputs.querySelector('[name=payload]').value = item.payload
    }

    if (item && Array.isArray(item.payload)) {
      inputs.querySelector('[name=payload]').remove()
    }

    const isNested = parentItem?.dataset?.role === 'nested'

    if (isNested) {
      const nestedFields = parentItem.querySelector('[data-navigation-editor-target="nestedInputFields"]')

      nestedFields.appendChild(inputs)
      nestedFields.querySelector('[data-action="navigation-editor#addNavigationItem"]')?.remove()
    } else {
      this.inputFieldsTarget.appendChild(inputs)
    }

    return inputs
  }

  buildTree (navigationItemNodes) {
    const items = []

    navigationItemNodes.forEach((item) => {
      const navigationItem = {}
      navigationItem.label = item.querySelector(':scope > input[name=label]').value

      const nestedNavigationItems = item.querySelectorAll('[data-navigation-editor-target="navigationItem"]')

      if (nestedNavigationItems.length > 0) {
        navigationItem.payload = this.buildTree(nestedNavigationItems)
      } else {
        const payloadField = item.querySelector(':scope > input[name=payload]')

        if (payloadField) {
          navigationItem.payload = payloadField.value
        }
      }

      items.push(navigationItem)
    })

    return items
  }

  handleNavigationFieldsChanged (event) {
    this.updateJSON()
  }

  navigationFieldsChanged () {
    document.dispatchEvent(navigationFieldsChangedEvent)
  }

  parseJSON () {
    try {
      return JSON.parse(this.jsonTarget.value)
    } catch (SyntaxError) {
      return { navigation: [] }
    }
  }

  removeNavigationItem (event) {
    event.preventDefault()

    const button = event.target
    const navigationItemElement = button.closest('[data-navigation-editor-target=navigationItem]')
    navigationItemElement.remove()

    this.navigationFieldsChanged()
  }

  updateInputFields () {
    const items = this.parseJSON().navigation

    items.forEach((item) => {
      const inputs = this.addNavigationItemElement(item, this.element)

      if (item.payload && Array.isArray(item.payload)) {
        item.payload.forEach((nItem) => {
          this.addNavigationItemElement(nItem, inputs)
        })
      }
    })
  }

  updateJSON () {
    if (!this.hasJsonTarget) return

    const items = this.buildTree(this.navigationItemTargets)

    this.jsonTarget.value = JSON.stringify(
      { navigation: items }
    )
  }
}

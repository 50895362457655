import { heroicons } from '../icons'
import Helpers from './plugin_helpers'
import RowTune from './row_tune'
import SizeTune from './size_tune'
import BackgroundColorTune from './background_color_tune'

class Video {
  static toolName

  static get toolbox () {
    return {
      title: 'Video',
      icon: heroicons('video-camera')
    }
  }

  static prepare (config) {
    Video.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.data.backgroundColor = this.data.backgroundColor || 'white'
    this.data.rows = this.data.rows || '1'
    this.data.size = this.data.size || '1/3'
    this.helpers = new Helpers(this.constructor.toolName)

    this.backgroundColorTune = new BackgroundColorTune(api, this.data.backgroundColor)
    this.rowTune = new RowTune(api, this.data.rows)
    this.sizeTune = new SizeTune(api, this.data.size)
  }

  render () {
    let container = document.createElement('div')
    const text = document.createTextNode('Video')

    container.appendChild(text)

    container = this.helpers.addDataToContainer(
      this.data,
      container
    )

    return container
  }

  renderSettings () {
    return [
      this.rowTune.rowTunesWrapper(
        // callback function
        (tune) => {
          this.data.rows = tune.rows
        }
      ),
      this.sizeTune.sizeTunesWrapper(
        // callback function
        (tune) => {
          this.data.size = tune.size
        }
      ),
      this.backgroundColorTune.colorTuneWrapper(
        // callback function
        (tune) => {
          this.data.backgroundColor = tune.color
        }
      )
    ]
  }

  save (blockContent) {
    let url = ''

    if (blockContent.querySelector('#url')) {
      const value = blockContent.querySelector('#url').value

      url = value
    }

    return {
      backgroundColor: this.data.backgroundColor,
      rows: this.data.rows,
      size: this.data.size,
      url
    }
  }
}

export default Video

import { Controller } from '@hotwired/stimulus'

// HorizontalScrollController
//
// Adds support for buttons that scroll a container horizontally when the user
// clicks on them. The container is scrolled smoothly left/right by the width of
// the first child element inside it.
//
// Targets
//
// - container: The element that will be scrolled.
//
// Actions
//
// - next: Scroll the container, revealing the next child element to the right
//
// - previous: Scroll the container, revealing the previous child element to the
//   left
export default class extends Controller {
  static classes = ['navigationActive', 'navigationInactive']

  static targets = ['container', 'navigation', 'scrollNext', 'scrollPrevious']

  connect () {
    this.containerTarget.addEventListener('scroll', function (args) {
      this.updateScrollTargets()
      this.updateNavigationTargets()
    }.bind(this))

    this.updateNavigationTargets()
    this.updateScrollTargets()
  }

  // Returns the first child element that is visible in the container
  firstFullyVisibleChild () {
    return this.fullyVisibleChildren()[0]
  }

  // Returns the child elements that are currently fully visible in the
  // container.
  fullyVisibleChildren () {
    return Array.from(this.containerTarget.children).filter(child => {
      const childLeft = child.offsetLeft
      const childRight = childLeft + child.offsetWidth
      return (childLeft >= this.scrollLeft() && childRight <= this.scrollRight())
    })
  }

  isScrolledToStart () {
    return this.scrollLeft() === 0
  }

  isScrolledToEnd () {
    return this.scrollRight() === this.containerTarget.scrollWidth
  }

  next () {
    const leftmostChild = this.firstFullyVisibleChild() || this.visibleChildren()[0]
    const leftmostIndex = parseInt(leftmostChild.dataset.index)
    const nextIndex = leftmostIndex + this.windowSize()
    this.scrollToItem(nextIndex)
  }

  previous () {
    const leftmostChild = this.firstFullyVisibleChild() || this.visibleChildren()[0]
    const leftmostIndex = parseInt(leftmostChild.dataset.index)
    const previousIndex = leftmostIndex - this.windowSize()
    this.scrollToItem(previousIndex)
  }

  // Scrolls the containerTarget horizontally by a given number of pixels
  scrollContainer (leftScroll) {
    this.containerTarget.scrollBy({
      left: leftScroll,
      behavior: 'smooth'
    })
  }

  scrollLeft () {
    return this.containerTarget.scrollLeft
  }

  scrollRight () {
    return this.scrollLeft() + this.containerTarget.clientWidth
  }

  scrollTo (event) {
    const targetIndex = event.target.dataset.index
    this.scrollToItem(targetIndex)
  }

  // Scrolls the container to reveal the item at the given index
  scrollToItem (index) {
    if (typeof (index) === 'undefined') { return }
    if (index < 0) { index = 0 }

    const targetElement = this.containerTarget.children[index]
    const scrollDifference = targetElement.offsetLeft - this.scrollLeft()
    this.scrollContainer(scrollDifference)
  }

  showScrollNext (visible) {
    if (visible) {
      this.scrollNextTarget.classList.remove('hidden')
    } else {
      this.scrollNextTarget.classList.add('hidden')
    }
  }

  showScrollPrevious (visible) {
    if (visible) {
      this.scrollPreviousTarget.classList.remove('hidden')
    } else {
      this.scrollPreviousTarget.classList.add('hidden')
    }
  }

  updateNavigationTargets (event) {
    const firstVisibleChild = this.firstFullyVisibleChild()
    if (!firstVisibleChild) { return }

    const activeIndex = firstVisibleChild.dataset.index
    if (!activeIndex) { return }

    this.navigationTargets.forEach((navigation, index) => {
      if (index === parseInt(activeIndex)) {
        if (this.hasNavigationActiveClass) { navigation.classList.add(this.navigationActiveClass) }
        if (this.hasNavigationInactiveClass) { navigation.classList.remove(this.navigationInactiveClass) }
      } else {
        if (this.hasNavigationInactiveClass) { navigation.classList.add(this.navigationInactiveClass) }
        if (this.hasNavigationActiveClass) { navigation.classList.remove(this.navigationActiveClass) }
      }
    })
  }

  updateScrollTargets () {
    this.showScrollNext(!this.isScrolledToEnd())
    this.showScrollPrevious(!this.isScrolledToStart())
  }

  // Returns all child elements that are currently visible in the container,
  // even if they are only partially visible.
  visibleChildren () {
    return Array.from(this.containerTarget.children).filter(child => {
      const childLeft = child.offsetLeft
      const childRight = childLeft + child.offsetWidth
      return (childRight >= this.scrollLeft() && childLeft <= this.scrollRight())
    })
  }

  // Returns the number of fully visible elements in the container. If no
  // element is fully visible, 1 is returned.
  windowSize () {
    const size = this.fullyVisibleChildren().length
    if (size === 0) {
      return 1
    } else {
      return size
    }
  }
}

const addActiveClassToButton = function (button) {
  button.classList.add('cdx-settings-button--active')
}

const clearOtherActiveButtons = function (wrapper, clickedButton) {
  wrapper.querySelectorAll('.cdx-settings-button--active').forEach(button => {
    if (button !== clickedButton) {
      removeActiveClassFromButton(button)
    }
  })
}

const removeActiveClassFromButton = function (button) {
  button.classList.remove('cdx-settings-button--active')
}

const handleTuneToggleAsRadioButton = function (button) {
  clearOtherActiveButtons(button.parentElement, button)
  addActiveClassToButton(button)
}

export { addActiveClassToButton, handleTuneToggleAsRadioButton }

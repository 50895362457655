import { Controller } from '@hotwired/stimulus'
import FormPersistence from 'form-persistence'

// FormPersistenceController
//
// Saves and retrieves form inputs to and from local storage
// Note: Form needs to have a somewhat unique id
export default class extends Controller {
  connect () {
    FormPersistence.persist(this.element)
  }
}

import { Controller } from '@hotwired/stimulus'

// This is a variant of
// https://www.stimulus-components.com/docs/stimulus-clipboard/ that works with
// the Clipboard API over Document.execCommand.
//
// When https://github.com/stimulus-components/stimulus-clipboard/pull/6 is
// merged we should be able to replace this controller with the package version.
export default class extends Controller {
  static targets = ['button', 'source']
  static values = {
    successDuration: Number
  }

  connect () {
    if (!this.hasButtonTarget) return

    this.originalText = this.buttonTarget.innerText
  }

  copy (event) {
    event.preventDefault()

    navigator.clipboard.writeText(this.sourceText()).then(() => this.copied())
  }

  copied () {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    this.buttonTarget.innerText = this.data.get('successContent')

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerText = this.originalText
    }, this.successDurationValue || 2000)
  }

  sourceText () {
    return this.sourceTarget.value
  }
}

import { Controller } from '@hotwired/stimulus'

// ComponentsLoadedListenerController
//
export default class extends Controller {
  connect () {
    document.addEventListener('components:all_loaded', () => { this.handleComponentsLoaded() })
    document.addEventListener('components:loading', () => { this.handleComponentsLoading() })
  }

  disconnect () {
    document.removeEventListener('components:all_loaded', () => { this.handleComponentsLoaded() })
    document.removeEventListener('components:loading', () => { this.handleComponentsLoading() })
  }

  handleComponentsLoaded () {
    this.submitButton().disabled = false
  }

  handleComponentsLoading () {
    this.submitButton().disabled = true
  }

  submitButton () {
    return this.element.querySelector("input[type='submit']")
  }
}

import { Controller } from '@hotwired/stimulus'

// ComponentsController
//
// Handles fetching the right input field (component) for the connected element.
// Any data atttributes on the connected element will be added as params to the fetched url.
//
// Example: `/admin/components/show?$type=club_ad&controller=component&signupgift_id=123`
//
export default class extends Controller {
  connect () {
    this.resetFetchCount()
    this.fetchHtml()
  }

  fetchHtml () {
    const dataQueryList = this.buildDataQueryList(this.element.dataset)
    const url = `/admin/components/show?${dataQueryList}`

    this.incrementFetchCount()

    window.fetch(url)
      .then(response => response.text())
      .catch((error) => {
        console.error('Error:', error)
        this.decrementFetchCount()
      })
      .then((data) => { this.element.innerHTML = data })
      .then(() => {
        this.decrementFetchCount()
      })
  }

  buildDataQueryList (dataset) {
    let params = Object.keys(dataset).map(key => {
      return `${key}=${encodeURIComponent(dataset[key])}`
    })

    // remove undefined values
    params = params.filter(param => param)

    return params.join('&')
  }

  decrementFetchCount () {
    globalThis.componentsControllerFetchCount--
    // console.log(`componentsControllerFetchCount: ${globalThis.componentsControllerFetchCount}`)
    this.broadcastLoadingState()
  }

  incrementFetchCount () {
    globalThis.componentsControllerFetchCount++
    // console.log(`componentsControllerFetchCount: ${globalThis.componentsControllerFetchCount}`)
    this.broadcastLoadingState()
  }

  resetFetchCount () {
    if (globalThis.componentsControllerFetchCount === undefined) {
      globalThis.componentsControllerFetchCount = 0
    }
    this.broadcastLoadingState()
  }

  broadcastLoadingState () {
    if (globalThis.componentsControllerFetchCount === 0) {
      const event = new Event('components:all_loaded')
      document.dispatchEvent(event)
    }

    if (globalThis.componentsControllerFetchCount > 0) {
      const event = new Event('components:loading')
      document.dispatchEvent(event)
    }
  }
}

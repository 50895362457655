import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

class FactBox {
  static toolName

  static get toolbox () {
    return {
      title: 'Faktaboks',
      icon: heroicons('information-circle')
    }
  }

  static prepare (config) {
    FactBox.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.helpers = new Helpers(this.constructor.toolName)
  }

  render () {
    const temporaryElement = this.helpers.buildTemporaryElement(
      'Faktaboks',
      this.data
    )

    return temporaryElement
  }

  save (blockContent) {
    const count = [0, 1, 2]
    const ids = []
    count.forEach(function (count) {
      const entryField = blockContent.querySelector(`[name="entry_ids_${count}"]`)
      if (entryField && entryField.value) {
        ids[count] = blockContent.querySelector(`[name="entry_ids_${count}"]`).value
      }
    })

    const facts = []
    const factFields = blockContent.querySelectorAll('[name="fact"]')
    factFields.forEach((field) => {
      facts.push(field.value)
    })

    let photoId = ''
    if (blockContent.querySelector('[name="photo_id"]')) {
      photoId = blockContent.querySelector('[name="photo_id"]').value
    }

    let title = ''
    if (blockContent.querySelector('[name="title"]')) {
      title = blockContent.querySelector('[name="title"]').value
    }

    let url = ''
    if (blockContent.querySelector('[name="url"]')) {
      url = blockContent.querySelector('[name="url"]').value
    }

    return {
      facts,
      photoId,
      title,
      url
    }
  }
}

export default FactBox

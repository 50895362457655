import { Controller } from '@hotwired/stimulus'

// SearchController
//
// Adds interactivity to Search UI
export default class extends Controller {
  static targets = ['filters', 'query', 'wrapper']

  toggleFilters (event) {
    event.preventDefault()

    const element = document.querySelector('[data-search-target="filters"]')

    if (this.isHidden(element)) {
      element.style.display = 'block'
    } else {
      element.style.display = 'none'
    }
  }

  toggleSearchFocus (event) {
    event.preventDefault()

    const element = event.target
    const searchInput = this.queryTarget

    if (element.classList.contains('bg-search-icon')) {
      searchInput.focus()
    } else {
      searchInput.value = ''
    }

    this.toggleSearchAndCloseIconClasses(element)
  }

  toggleNavigationSearchFocus (event) {
    event.preventDefault()

    const searchInput = this.queryTarget
    const inputWrapper = this.wrapperTarget

    if (this.isHidden(inputWrapper)) {
      searchInput.value = ''
    } else {
      searchInput.focus()
    }
  }

  isHidden (element) {
    return element.offsetParent === null || element.style.display === 'none'
  }

  toggleSearchAndCloseIconClasses (element) {
    element.classList.toggle('bg-search-icon')
    element.classList.toggle('bg-close-icon')
  }
}

import { heroicons } from '../icons'
import BackgroundColorTune from './background_color_tune'
import Helpers from './plugin_helpers'

class ClubCategoryGroupList {
  static toolName

  static get toolbox () {
    return {
      title: 'Klub Kategori grupper',
      icon: heroicons('clipboard-list')
    }
  }

  static prepare (config) {
    ClubCategoryGroupList.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.data.backgroundColor = this.data.backgroundColor || 'white'
    this.helpers = new Helpers(this.constructor.toolName)

    this.backgroundColorTune = new BackgroundColorTune(api, this.data.backgroundColor)
  }

  render () {
    const temporaryElement = this.helpers.buildTemporaryElement(
      'Klub Kategori grupper',
      this.data
    )

    return temporaryElement
  }

  renderSettings () {
    return [
      this.backgroundColorTune.colorTuneWrapper(
        // callback function
        (tune) => {
          this.data.backgroundColor = tune.color
        }
      )
    ]
  }

  save (blockContent) {
    const ids = []
    const categoriesCount = [0, 1, 2]

    categoriesCount.forEach(function (count) {
      const field = blockContent.querySelector(`[name="categoryGroupIds_${count}"]`)
      if (field && field.value) {
        ids[count] = blockContent.querySelector(`[name="categoryGroupIds_${count}"]`).value
      }
    })

    return {
      backgroundColor: this.data.backgroundColor,
      categoryGroupIds: ids,
      size: this.data.size
    }
  }
}

export default ClubCategoryGroupList

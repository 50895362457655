import { Controller } from '@hotwired/stimulus'

// Adds interactivity to Age recommendation fields
export default class extends Controller {
  static targets = ['customInputField', 'recommendedAge']

  connect () {
    this.recommendedAgeTargets.forEach(element => {
      element.addEventListener('change', this.recommendedAgeChanged.bind(this))
    })
  }

  disconnect () {
    this.recommendedAgeTargets.forEach(element => {
      element.removeEventListener('change', this.recommendedAgeChanged.bind(this))
    })
  }

  recommendedAgeChanged () {
    this.customInputFieldTargets.forEach(inputField => {
      inputField.disabled = this.recommendedAgeValue() !== 'custom'
    })
  }

  recommendedAgeValue () {
    return this.currentRecommendedAgeTarget().value
  }

  currentRecommendedAgeTarget () {
    return this.recommendedAgeTargets.find(element => {
      return element.checked
    })
  }
}

import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

import BackgroundColorTune from './background_color_tune'

class Bubbles {
  static toolName

  static get toolbox () {
    return {
      title: 'Bobler',
      icon: heroicons('dots-horizontal')
    }
  }

  static prepare (config) {
    Bubbles.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.data.backgroundColor = this.data.backgroundColor || 'sandy-pale'
    this.helpers = new Helpers(this.constructor.toolName)

    this.backgroundColorTune = new BackgroundColorTune(api, this.data.backgroundColor)
  }

  render () {
    const temporaryElement = this.helpers.buildTemporaryElement(
      'Bobler',
      this.data
    )

    return temporaryElement
  }

  renderSettings () {
    return [
      this.backgroundColorTune.colorTuneWrapper(
        (tune) => {
          this.data.backgroundColor = tune.color
        }
      )
    ]
  }

  save (blockContent) {
    const count = [0, 1, 2, 3, 4, 5]
    const ids = []
    count.forEach(function (count) {
      const entryField = blockContent.querySelector(`[name="entry_ids_${count}"]`)
      if (entryField && entryField.value) {
        const entryId = entryField.value
        const titleField = blockContent.querySelector(`[name="entries_${count}_custom_title"]`)
        let titleForEntry = ''

        if (titleField && titleField.value) {
          titleForEntry = titleField.value
        }

        ids[count] = { id: entryId, title: titleForEntry }
      }
    })

    let titleLine1 = ''
    if (blockContent.querySelector('[name="title_line_1"]')) {
      titleLine1 = blockContent.querySelector('[name="title_line_1"]').value
    }

    let titleLine2 = ''
    if (blockContent.querySelector('[name="title_line_2"]')) {
      titleLine2 = blockContent.querySelector('[name="title_line_2"]').value
    }

    return {
      backgroundColor: this.data.backgroundColor,
      entry_ids: ids,
      title_line_1: titleLine1,
      title_line_2: titleLine2
    }
  }
}

export default Bubbles

/* global CustomEvent */

import { Controller } from '@hotwired/stimulus'

// SignupGiftOptionSelectorController
//
// Wraps a select element and triggers a `signup-gift-option:selected` event
// whenever the dropdown value is changed.
export default class extends Controller {
  changed (event) {
    const signupGiftOptionSelectedEvent = new CustomEvent(
      'signup-gift-option:selected',
      {
        bubbles: true,
        detail: {
          selectedOption: this.selectedOption()
        }
      }
    )

    this.element.dispatchEvent(signupGiftOptionSelectedEvent)
  }

  selectedOption () {
    return this.element.value
  }
}

import { Controller } from '@hotwired/stimulus'

const Uppy = require('@uppy/core')
const DragDrop = require('@uppy/drag-drop')
const XHRUpload = require('@uppy/xhr-upload')
const Danish = require('@uppy/locales/lib/da_DK')

// Manages uploading 1 or more photos to Cloudinary. Each uploaded photo
// results in an instance of templateTarget being added to targetTarget, which
// can contain the id of the created Photo and a key to the asset on Cloudinary.
export default class extends Controller {
  static targets = ['target', 'dragDrop', 'template']

  static values = {
    dropSize: String,
    uploadEndpoint: String
  }

  addPhoto (photo) {
    let content = this.templateTarget.innerHTML
    content = content.replace(/NEW_RECORD/g, new Date().getTime().toString())
    content = content.replace(/PHOTO_ID/g, photo.id)
    content = content.replace(/CLOUDINARY_KEY/g, photo.cloudinary_key)
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }

  removePhoto (event) {
    event.target.parentElement.remove()
  }

  setupUppy () {
    // Clear previous dragdrop html before adding new
    // This enables continuous dragdropping of photos
    this.dragDropTarget.innerHTML = ''

    const uppy = new Uppy(this.optionsForUppy())
      .use(DragDrop, this.optionsForDragDrop())
      .use(XHRUpload, this.optionsForXHRUpload())

    uppy.on('complete', (result) => {
      const photos = []
      const uploaded = result.successful || []
      uploaded.forEach(uploadedPhoto => {
        uploadedPhoto.response.body.forEach(photo => {
          photos.push(photo)
        })
      })

      photos.forEach(photo => {
        this.addPhoto(photo)
      })

      this.setupUppy()
    })
  }

  connect () {
    this.setupUppy()
  }

  optionsForDragDrop () {
    return {
      target: this.dragDropTarget,
      locale: {
        strings: {
          dropHereOr: 'Træk og slip fil her eller vælg fil'
        },
        browse: 'vælg fil'
      },
      height: `${this.dropSizeValue}`
    }
  }

  optionsForUppy () {
    return {
      autoProceed: true,
      locale: Danish,
      restrictions: {
        maxNumberOfFiles: 1
      }
    }
  }

  optionsForXHRUpload () {
    return {
      bundle: true,
      endpoint: this.uploadEndpointValue
    }
  }
}

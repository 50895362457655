import { heroicons } from '../icons'
import BackgroundColorTune from './background_color_tune'
import Helpers from './plugin_helpers'
import RowTune from './row_tune'
import SizeTune from './size_tune'

class EntryGrid {
  static toolName

  static get toolbox () {
    return {
      title: '4 indholdskort i net',
      icon: heroicons('view-grid')
    }
  }

  static prepare (config) {
    EntryGrid.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.data.backgroundColor = this.data.backgroundColor || 'white'
    this.data.rows = this.data.rows || '1'
    this.data.size = this.data.size || '1/3'
    this.helpers = new Helpers(this.constructor.toolName)

    this.backgroundColorTune = new BackgroundColorTune(api)
    this.rowTune = new RowTune(api, this.data.rows)
    this.sizeTune = new SizeTune(api, this.data.size)
  }

  render () {
    const temporaryElement = this.helpers.buildTemporaryElement(
      '4 indholds kort i net',
      this.data
    )

    return temporaryElement
  }

  renderSettings () {
    return [
      this.rowTune.rowTunesWrapper(
        // callback function
        (tune) => {
          this.data.rows = tune.rows
        }
      ),
      this.sizeTune.sizeTunesWrapper(
        // callback function
        (tune) => {
          this.data.size = tune.size
        }
      ),
      this.backgroundColorTune.colorTuneWrapper(
        // callback function
        (tune) => {
          this.data.backgroundColor = tune.color
        }
      )
    ]
  }

  save (blockContent) {
    const ids = []
    const entriesCount = [0, 1, 2, 3]
    let url = ''
    let title = ''
    const entryGridTitleField = blockContent.querySelector('[name="title"]')
    const urlField = blockContent.querySelector('[name="url"]')

    entriesCount.forEach(function (count) {
      const entryField = blockContent.querySelector(`[name="entry_ids_${count}"]`)

      if (entryField && entryField.value) {
        const titleField = blockContent.querySelector(`[name="entries_${count}_custom_title"]`)
        let titleForEntry = ''

        if (titleField && titleField.value) {
          titleForEntry = titleField.value
        }

        ids[count] = { id: entryField.value, title: titleForEntry }
      }
    })

    if (entryGridTitleField && entryGridTitleField.value) {
      title = entryGridTitleField.value
    }

    if (urlField && urlField.value) {
      url = urlField.value
    }

    return {
      backgroundColor: this.data.backgroundColor,
      entry_ids: ids,
      rows: this.data.rows,
      size: this.data.size,
      title,
      url
    }
  }
}

export default EntryGrid

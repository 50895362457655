import { Controller } from '@hotwired/stimulus'

// FactBoxController
//
// Adds interactivity to Tools::FactBoxComponent
export default class extends Controller {
  static targets = ['container', 'inputFields', 'template']

  addFact (event) {
    event.preventDefault()
    this.addInputFieldForFact()
  }

  addInputFieldForFact () {
    const inputs = this.templateTarget.content.cloneNode(true)

    // Assume the first input element is the one we store the fact in
    inputs.querySelector('input').value = ''

    this.inputFieldsTarget.appendChild(inputs)
  }
}

import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

export default class ClubHeroImage {
  static toolName

  static get toolbox () {
    return {
      title: 'ClubHeroImage',
      icon: heroicons('user-group')
    }
  }

  static prepare (config) {
    ClubHeroImage.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.helpers = new Helpers(this.constructor.toolName)
  }

  render () {
    let container = document.createElement('div')
    const text = document.createTextNode('ClubHeroImage')

    container.appendChild(text)

    container = this.helpers.addDataToContainer(
      this.data,
      container
    )

    return container
  }

  save (blockContent) {
    const photoIds = []
    const photoIdFields = blockContent.querySelectorAll('[name="photo_id"]')
    photoIdFields.forEach((field) => {
      const photoId = field.value
      if (photoId) {
        photoIds.push(field.value)
      }
    })

    return {
      photo_ids: photoIds
    }
  }
}

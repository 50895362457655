import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

class ClubOfferTeaser {
  static toolName

  static get toolbox () {
    return {
      title: 'Klub tilbud',
      icon: heroicons('gift')
    }
  }

  static prepare (config) {
    ClubOfferTeaser.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.helpers = new Helpers(this.constructor.toolName)
  }

  render () {
    let container = document.createElement('div')
    const text = document.createTextNode('Klub tilbud')

    container.appendChild(text)

    container = this.helpers.addDataToContainer(
      this.data,
      container
    )

    return container
  }

  save (blockContent) {
    let signupgiftId = ''

    if (blockContent.querySelector('#signupgift_id')) {
      const value = blockContent.querySelector('#signupgift_id').value

      signupgiftId = value
    }

    return {
      signupgift_id: signupgiftId
    }
  }
}

export default ClubOfferTeaser

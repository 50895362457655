import { Controller } from '@hotwired/stimulus'

// Adds interactivity to Tools::PlanSelectorComponent
export default class extends Controller {
  static targets = ['container', 'inputFields', 'select', 'template']

  addPlan (event) {
    const selectedIndex = this.selectTarget.selectedIndex
    const selectedValue = this.selectTarget.options[selectedIndex].value
    const selectedName = this.selectTarget.options[selectedIndex].text

    this.addInputFieldForPlan(selectedValue, selectedName)
  }

  addInputFieldForPlan (id, name) {
    let content = this.templateTarget.innerHTML
    content = content.replace(/PLAN_ID/g, id)
    content = content.replace(/PLAN_NAME/g, name)
    this.inputFieldsTarget.insertAdjacentHTML('beforeend', content)
  }

  removePlan (event) {
    event.target.closest('[data-plan-selector-target="plan"]').remove()
  }
}

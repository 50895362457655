/* global Turbolinks */

let listenersAttached = false

document.addEventListener('turbolinks:load', function () {
  if (listenersAttached) {
    return false
  }
  const requestedByAppMeta = document.querySelector('meta[name="requested_by_native_app"]')
  if (!requestedByAppMeta) { return false }

  if (requestedByAppMeta.getAttribute('content') === 'true') {
    // GET requests should be handled like a Turbolinks.visit
    // in order to properly load the page content
    document.addEventListener('ajax:beforeSend', function (event) {
      const target = event.target
      const targetDataset = target.dataset
      const url = event.detail[1].url

      if (targetDataset && targetDataset.remote) {
        if (target.method === 'get') {
          event.stop()
          Turbolinks.visit(url)
        }
      }
    })

    // POST requests should clear the Turbolinks cache and set
    // the content manually to make it behave properly on native apps.
    // By doing this we ensure that validation errors are shown in the forms
    document.addEventListener('ajax:success', function (event) {
      const target = event.target
      const targetDataset = target.dataset
      const xhr = event.detail[2]
      const responseText = xhr.responseText

      if (targetDataset && targetDataset.remote) {
        if (target.method !== 'get') {
          // If response does not contain any Turbolinks api calls we assume it
          // is a validation error caused by bad data submitted by a form
          // and thus we should just render the content and not redirect
          if (responseText && responseText.substring(0, 10) !== 'Turbolinks') {
            Turbolinks.clearCache()
            document.documentElement.innerHTML = responseText
            Turbolinks.dispatch('turbolinks:load')
            window.scroll(0, 0)
          } else {
            // If on the other hand we should redirect, a message is sent to the
            // native application with the location of the redirect. The native
            // application does not do anything with this message, but if we at
            // some point need to know where to redirect, we can find out.
            const location = xhr.getResponseHeader('location')
            window.BibNative.broadcastFormSubmitted({
              redirectTo: location
            })

            // Actually process the redirect
            Turbolinks.visit(location)
          }
        }
      }
    })

    listenersAttached = true
  }
})

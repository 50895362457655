import { Controller } from '@hotwired/stimulus'
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/css/bundle'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

Swiper.use([Navigation, Pagination])

// CarouselController
//
// Stimulus controller that uses Swiper to carousel through
// images by clicking on arrows to swipe left and right
export default class extends Controller {
  static targets = ['pagination', 'next', 'previous']

  connect () {
    this.swiper = new Swiper(this.element, {
      loop: true,
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.previousTarget
      },
      pagination: {
        el: this.paginationTarget
      }
    })

    document.addEventListener('signup-gift-option:selected', this.handleSignupGiftOptionSelected.bind(this))
  }

  disconnect () {
    document.removeEventListener('signup-gift-option:selected', this.handleSignupGiftOptionSelected.bind(this))
  }

  handleSignupGiftOptionSelected (event) {
    const selectedOption = event.detail.selectedOption
    this.slideToSlideWithSignupGiftOptionId(selectedOption)
  }

  slideToSlideWithSignupGiftOptionId (signupGiftOptionId) {
    const slide = this.swiper.slides.find((slide) => {
      return slide.dataset.signupGiftOptionId === signupGiftOptionId
    })

    if (slide) {
      const index = slide.dataset.swiperSlideIndex
      this.swiper.slideTo(index)
    }
  }
}

import { heroicons } from '../icons'
import BackgroundColorTune from './background_color_tune'
import Helpers from './plugin_helpers'
import RowTune from './row_tune'
import SizeTune from './size_tune'

class ClubAd {
  static toolName

  static get toolbox () {
    return {
      title: 'Klub reklame',
      icon: heroicons('newspaper')
    }
  }

  static prepare (config) {
    ClubAd.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.data.backgroundColor = this.data.backgroundColor || 'white'
    this.data.rows = this.data.rows || '1'
    this.data.size = this.data.size || '1/3'
    this.helpers = new Helpers(this.constructor.toolName)

    this.backgroundColorTune = new BackgroundColorTune(api, this.data.backgroundColor)
    this.rowTune = new RowTune(api, this.data.rows)
    this.sizeTune = new SizeTune(api, this.data.size)
  }

  render () {
    let container = document.createElement('div')
    const text = document.createTextNode('Klub reklame')

    container.appendChild(text)

    container = this.helpers.addDataToContainer(
      this.data,
      container
    )

    return container
  }

  renderSettings () {
    return [
      this.rowTune.rowTunesWrapper(
        // callback function
        (tune) => {
          this.data.rows = tune.rows
        }
      ),
      this.sizeTune.sizeTunesWrapper(
        // callback function
        (tune) => {
          this.data.size = tune.size
        }
      ),
      this.backgroundColorTune.colorTuneWrapper(
        // callback function
        (tune) => {
          this.data.backgroundColor = tune.color
        }
      )
    ]
  }

  save (blockContent) {
    let signupgiftId = ''

    if (blockContent.querySelector('#signupgift_id')) {
      const value = blockContent.querySelector('#signupgift_id').value

      signupgiftId = value
    }

    return {
      backgroundColor: this.data.backgroundColor,
      signupgift_id: signupgiftId,
      rows: this.data.rows,
      size: this.data.size
    }
  }
}

export default ClubAd

import { Controller } from '@hotwired/stimulus'

// EntryGalleryController
//
// Adds interactivity to Tools::EntryGalleryComponent
export default class extends Controller {
  static targets = ['entryId', 'entryName', 'entryCustomTitle']

  removeEntry (event) {
    const count = event.target.dataset.entryCount
    this.entryIdTargets[count].value = ''
    this.entryNameTargets[count].value = ''
    this.entryCustomTitleTargets[count].value = ''
  }
}

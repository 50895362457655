import { Controller } from '@hotwired/stimulus'

// CategoryInputController
//
// Stimulus controller that manages two related select components to allow user
// to pick a single child category in a primary category.
//
// To allow users to add multiple categories to a single form, see
// CategoriesInputController.
export default class extends Controller {
  static targets = ['childCategory', 'primaryCategory']

  connect () {
    this.primaryCategoryTarget.addEventListener(
      'change',
      this.primaryCategoryChanged.bind(this)
    )

    this.showOnlyChildCategoriesForParent(this.primaryCategoryId())
  }

  disconnect () {
    this.primaryCategoryTarget.removeEventListener(
      'change',
      this.primaryCategoryChanged.bind(this)
    )
  }

  hide (element) {
    element.classList.add('hidden')
    element.querySelectorAll('input, select').forEach(inputElement => {
      inputElement.disabled = true
    })
  }

  primaryCategoryChanged (event) {
    this.showOnlyChildCategoriesForParent(this.primaryCategoryId())
  }

  primaryCategoryId () {
    return this.primaryCategoryTarget.value
  }

  removeCategory (event) {
    event.preventDefault()

    this.element.remove()
  }

  show (element) {
    element.classList.remove('hidden')
    element.querySelectorAll('input, select').forEach(inputElement => {
      inputElement.disabled = false
    })
  }

  showOnlyChildCategoriesForParent (primaryCategoryId) {
    this.childCategoryTargets.forEach(element => {
      if (element.dataset.parent === primaryCategoryId) {
        this.show(element)
      } else {
        this.hide(element)
      }
    })
  }
}

/* global ga */
/* global BibNative */

import { Controller } from '@hotwired/stimulus'

// SponsorTrackingController
//
// Tracks the user's click on a sponsor link
export default class extends Controller {
  connect () {
    const element = this.element

    this.track(
      {
        hitType: 'event',
        eventCategory: 'Sponsored',
        eventAction: 'view',
        eventLabel: element.href,
        eventValue: 0
      }
    )
  }

  trackClick (event) {
    const element = this.element

    // if the sponsored link is clicked,
    // send the "Click" tracking using the link href as label
    this.track(
      {
        hitType: 'event',
        eventCategory: 'Sponsored',
        eventAction: 'click',
        eventLabel: element.href
      }
    )

    // Don't attempt to stop event on native apps since it does not cause
    // a new page load. So the event should be tracked just fine
    // It is important we get the proper event sent to the app so it can handle the
    // navigation action properly.
    if (!window.BibNative || !BibNative.requestedByNativeApp()) {
      event.preventDefault() // We'll handle the click - a bit delayed, though, to improve chances that we track the click
      setTimeout(function () {
        document.location = element.href
      }, 200)
    }
  }

  track (payload) {
    if (typeof (ga) === 'undefined') return

    ga('send', payload)
  }
}

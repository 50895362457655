import { heroicons } from '../icons'
import Helpers from './plugin_helpers'
import RowTune from './row_tune'
import SizeTune from './size_tune'

class AppAd {
  static toolName

  static get toolbox () {
    return {
      title: 'App annonce',
      icon: heroicons('newspaper')
    }
  }

  static prepare (config) {
    AppAd.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.data.rows = this.data.rows || '1'
    this.data.size = this.data.size || '1/3'
    this.helpers = new Helpers(this.constructor.toolName)

    this.rowTune = new RowTune(api, this.data.rows)
    this.sizeTune = new SizeTune(api, this.data.size)
  }

  render () {
    let container = document.createElement('div')
    const text = document.createTextNode('App annonce')

    container.appendChild(text)

    container = this.helpers.addDataToContainer(
      this.data,
      container
    )

    return container
  }

  renderSettings () {
    return [
      this.rowTune.rowTunesWrapper(
        // callback function
        (tune) => {
          this.data.rows = tune.rows
        }
      ),
      this.sizeTune.sizeTunesWrapper(
        // callback function
        (tune) => {
          this.data.size = tune.size
        }
      )
    ]
  }

  save () {
    return {
      rows: this.data.rows,
      size: this.data.size
    }
  }
}

export default AppAd

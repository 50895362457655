import { heroicons } from '../icons'
import Helpers from './plugin_helpers'

class ClubValueBenefits {
  static toolName

  static get toolbox () {
    return {
      title: 'Værdiberegning af klub',
      icon: heroicons('calculator')
    }
  }

  static prepare (config) {
    ClubValueBenefits.toolName = config.toolName
  }

  constructor ({ api, data }) {
    this.api = api
    this.data = data
    this.helpers = new Helpers(this.constructor.toolName)
  }

  render () {
    let container = document.createElement('div')
    const text = document.createTextNode('Værdiberegning af klub')

    container.appendChild(text)

    container = this.helpers.addDataToContainer(
      this.data,
      container
    )

    return container
  }

  save (blockContent) {
    return this.data
  }
}

export default ClubValueBenefits

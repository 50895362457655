import { addActiveClassToButton, handleTuneToggleAsRadioButton } from './tune_handlers'
import { heroicons } from '../icons'

// SizeTune
//
// Size tune helpers for connected EditorJs tool
//
class SizeTune {
  constructor (api, size) {
    this.api = api
    this.size = size
  }

  settings () {
    const icon = heroicons('arrows-pointing-out')

    return [
      {
        name: '1/4 størrelse',
        icon,
        size: '1/4'
      },
      {
        name: '2/4 størrelse',
        icon,
        size: '2/4'
      },
      {
        name: '1/3 størrelse',
        icon,
        size: '1/3'
      },
      {
        name: '2/3 størrelse',
        icon,
        size: '2/3'
      },
      {
        name: 'Fuld bredde',
        icon,
        size: '3/3'
      }
    ]
  }

  addOnHoverToButton (button, tune) {
    this.api.tooltip.onHover(button, tune.name)
  }

  appendButtons (wrapper, callback) {
    this.settings().forEach(tune => {
      const button = document.createElement('div')

      button.classList.add('cdx-settings-button')
      button.innerHTML = tune.icon
      wrapper.appendChild(button)

      if (this.size === tune.size) {
        addActiveClassToButton(button)
      }

      button.addEventListener('click', function () {
        handleTuneToggleAsRadioButton(button)
        callback(tune)
      })

      this.addOnHoverToButton(button, tune)
    })

    return wrapper
  }

  sizeTunesWrapper (callback) {
    let wrapper = document.createElement('div')

    wrapper = this.appendButtons(wrapper, callback)

    return wrapper
  }
}

export default SizeTune

/* global fetch */

import { Controller } from '@hotwired/stimulus'

// ViewCountController
//
// Calls our backend to increment the view count for a given entry included in the url
export default class extends Controller {
  connect () {
    this.incrementViewCount(this.element)
  }

  incrementViewCount (element) {
    const url = element.dataset.url

    fetch(url, { method: 'POST' })
  }
}

import { Controller } from '@hotwired/stimulus'
import { useResize } from 'stimulus-use'

// Logo Scroll Controller
export default class extends Controller {
  static targets = ['scrollable']

  static values = {
    // The number of ms is should take to scroll the image a full round
    duration: { type: Number, default: 30000 }
  }

  connect () {
    // Store the original dimension of the scrollable element. This is the
    // dimensions of the image we want to scroll, which isn't supposed to
    // change.
    //
    // The dimensions should be set via CSS or similiar so we don't depend on
    // images having been loaded.
    this.originalHeight = this.scrollableTarget.scrollHeight
    this.originalWidth = this.scrollableTarget.scrollWidth

    this.createScrollableAnimation(this.scrollableTarget)

    useResize(this)
  }

  createScrollableAnimation (element) {
    // Get the left offset from the viewport left edge
    const offset = element.getBoundingClientRect().left

    // Start the scroll animation so that...
    //
    // 1. ... the element is scrolled to the left enough that it extend past the
    //    left edge of the viewport.
    const repetitionsLeft = Math.ceil(offset / this.scrollableWidth())

    // 2. ... a repetition of the image is exactly where the image is placed
    //    before we started scrolling. This prevents any flickers/jumps in the
    //    position when the animation starts.
    const startOffset = 0 - repetitionsLeft * this.originalWidth
    const endOffset = startOffset - this.originalWidth

    const transformFrom = `translateX(${startOffset}px)`
    const transformTo = `translateX(${endOffset}px)`

    element.animate([
      { transform: transformFrom },
      { transform: transformTo }
    ], {
      duration: this.durationValue,
      iterations: Infinity
    })
  }

  // Returns the number of repetitions of the background image needed to cover
  // the full width of the viewport.
  //
  // We need to repeat the image at least once, but we'll repeat it until it
  // covers the full width of the viewport plus an extra repetition at the end
  // so we never see the end scroll into view, making the scrolling appear
  // infinite.
  numberOfRepetitions () {
    return Math.ceil(this.viewportWidth() / this.originalWidth) + 1
  }

  prepareElementForScrolling (element) {
    // Set the background image size to the size of the scrollable element. This
    // replicates background-size: cover, but works with a repeating background
    // image.
    element.style.backgroundSize = `${this.originalWidth}px ${this.originalHeight}px`

    // Repeat the background image horizontally so it looks like we're scrolling
    // indefinitely.
    element.style.backgroundRepeat = 'repeat-x'

    // Increase the element width so we can actually see the background image
    // repeating.
    const repetitions = this.numberOfRepetitions()
    element.style.width = `${this.originalWidth * repetitions}px`
  }

  resize () {
    this.prepareElementForScrolling(this.scrollableTarget)
  }

  scrollableWidth () {
    return this.scrollableTarget.scrollWidth
  }

  viewportWidth () {
    return window.innerWidth
  }
}
